<template>
  <div style="padding: 0 20px; text-align: left;font-size:12px">
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >莲藕医生（医生端）尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >在同意本应用服务使用政策之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用政策不可分割的一部分。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >【</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >特别提示</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >】</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >本政策仅适用于广东莲藕健康科技有限公司（以下或称“我们”）提供的产品和服务，包括莲藕医生（医生端）APP以及随技术发展出现的新形态向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务政策当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务政策未涵盖的内容，以本政策内容为准。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >一、我们如何收集和使用您的个人信息</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息；个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本政策涉及的个人信息和个人敏感信息均出自于GB/T35273-2020《个人信息安全规范》，您可在政策的本部分（即“二“这一部分）可查找到我们收集的个人信息，且涉及到的个人敏感信息均采用字体加粗的显著标识。我们的部分服务可能需要您提供个人信息或个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >我们收集信息是为了向您提供优质的服务，我们将出于以下目的收集您的个人信息：</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >一</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >）</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >提供您使用的各项服务</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >注册成为医生</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >若您为莲藕医生（医生端）的用户，为完成创建账号，您需要提供注册的手机号码、密码。由于医生职业的严肃性、专业性和重要性，为了保障咨询服务安全可信，按照《互联网医院管理办法（试行）》规定，您还需提供以下信息：您的基本信息，包括姓名、答题科室、职称、医院、医院所在科室、擅长及诊所介绍；您的执照证明信息，包括医师资格证书、医师执业证书及职称证书、医院工牌等图片；您的身份证号和身份证正反面照片；您的手写签名图片。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >提供上述信息并同意《莲藕医生（医生端）服务协议》和本隐私政策后，您可以使用莲藕医生（医生端）诊所的核心业务功能，包括：图文咨询、电话咨询、门诊患者、患者管理等。如果您选择不提供为实现莲藕医生（医生端）核心业务功能的必备信息，将导致我们无法为您提供该功能。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除上述信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会单独征得您的授权同意。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >二</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >）</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >收集并记录相关信息</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >您在使用我们服务时搜索、查看的信息，在您使用平台服务，或访问平台网页时，系统自动接收并记录的您的浏览器和计算机上的信息，包括您的IP地址、浏览器信息、访问日期和时间、语言、软硬件特征信息及您浏览的网页记录等数据。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（三）开展内部审计、数据分析和研究，改善我们的产品和服务</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >您与我们的客户服务团队联系时您提供的相关信息，您参与问卷调查活动时向我们提供的问卷答复信息，以及您与的关联方、合作伙伴之间互动时我们收集的相关信息，如您访问移动网页使用平台服务，或下载或使用或其关联公司客户端软件，可能会读取您的移动设备相关的信息，包括设备型号、操作系统、设备识别码、分辨率、电信运营商等。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（四）安全保障</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或春雨相关协议规则的情况，我们会通过单独使用或综合分析您的账户信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，并且记录一些您的网络使用习惯、您常用的软件信息、我们认为有风险的URL的手段来判断您账户的风险。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #494949;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（五）为您提供其他附加服务</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、设备信息，为了提供更好的产品和/或服务并改善用户体验，我们会在后台状态下收集设备属性信息（例如您的硬件型号、操作系统版本、设备配置、硬件序列号、唯一设备标识符、国际移动设备身份码IMEI、网络设备硬件MAC地址、广告标识符IDFA），设备位置信息（如通过GPS、蓝牙或Wi-Fi信号获得的位置信息）、设备连接信息（浏览器的类型、电信运营商、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表）。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #222222;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2、基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行拍摄照片或视频用于</span><span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >拍摄、拍照、扫码等功能。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #222222;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >3、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现更换头像、与患者沟通等功能。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #222222;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >4、基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现直播、回复患者、录制视频等功能。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >5、我们会读取软件安装列表，用于软件自助升级服务。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >6、当您开启设备定位功能并使用我们基于位置提供的相关服务时，我们可能会收集和处理有关您的设备信息、位置信息，以使得您不需要手动输入自身地理坐标就可获得相关服务。我们会使用各种技术进行定位，这些技术包括IP地址、GPS以及能够提供相关信息的其他传感器（比如可能会为我们提供附近设备、Wi-Fi接入点和基站的信息）。您可以通过系统授权关闭定位功能，停止我们对您的地理位置信息的收集，但之后您可能将无法使用相关服务或功能，或者无法达到相关服务拟达到的效果。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >7、当您有使用注册、在线问诊、分享等服务时，我们将获取您的ANDROID ID，以便于我们作为设备唯一标识为您提供相应服务。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #222222;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >经您理解并同意，上述附加服务可能需要您在您的设备中开启您的摄像头（相机）、相册（图片库）、麦克风（语音）的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt 7.5pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #222222;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >当您使用我们提供的功能或服务时，在某些特定使用场景下，我们可能会使用具有相应业务资质及能力的第三方服务商提供的软件服务工具包（简称“SDK”）来为您提供服务，由第三方服务商收集您的必要信息，如不授权该权限将不能使用所对应的功能服务。具体包括以下几种：</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >腾讯即时通讯IM</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：腾讯</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：链接用户和医生进行问诊</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >极光通讯</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：极光</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：帮助用户接收提醒消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >微信 SDK</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：腾讯</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：帮助用户使用微信登录、微信支付、和微信分享</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <!-- <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >百度定位 SDK</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：腾讯</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：帮助用户获取位置信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p> -->
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >小米 PUSH</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：小米</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：推送消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >VIVO PUSH</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：VIVO</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：推送消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            background-color: #ffffff;
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >OPPO PUSH</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：OPPO</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：推送消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >魅族 PUSH</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：魅族</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：推送消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >华为 PUSH</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：华为</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：推送消息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >MobTech一键登录</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：MobTech袤博</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：用于手机号一键登录</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >联通帐号认证</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：中国联通</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：用于联通手机号一键登录</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息、电话号码</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >天翼账号认证</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：中国电信</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：用于电信手机号一键登录</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息、电话号码</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >移动账号认证</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：中国移动</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：用于移动手机号一键登录</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息、电话号码</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >腾讯Bugly</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >所属机构：腾讯</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >使用目的：收集奔溃日志和推送更省心</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >数据类型：设备标识信息、电话号码</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >二、我们如何共享、转让、公开披露您的个人信息</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（一）共享</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >除本公司及本公司的关联公司外，我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >3、与我们的关联公司共享：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >4、与授权合作伙伴共享：仅为实现本隐私权政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（二）转让</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >除本公司及本公司的关联公司外，我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、</span><span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2、</span><span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >（三）公开披露</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >我们仅会在以下情况下，公开披露您的个人信息：</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
          text-indent: 0pt;
        "
    >
      <span
        style="color: #333333; font-family: 微软雅黑; font-size: 10pt"
      >1、</span><span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >获得您明确同意后；</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
          text-indent: 0pt;
        "
    >
      <span
        style="color: #111111; font-family: 微软雅黑; font-size: 10pt"
      >2、</span><span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >三、我们如何使用 Cookie 和同类技术</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie
        的小数据文件。Cookie
        通常包含标识符、站点名称以及一些号码和字符。借助于
        Cookie，我们能够存储您的偏好或商品等数据，并用以判断注册用户是否已经登录，提升服务/产品质量及优化用户体验，通过得到的
        Cookies 信息，为您提供相关服务。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2、我们不会将 Cookie
        用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机上或手机中保存的所有
        Cookie，大部分网络浏览器都设有阻止或禁用 Cookie
        的功能，您可对浏览器进行配置；APP
        设置中设有【清除缓存】功能。但如果您这么做，则需要在每一次访问我们的平台时亲自更改用户设置。阻止或禁用
        Cookie
        功能后，可能影响您使用或不能充分使用莲藕医生（医生端）提供的全部产品和/或服务。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >四、我们如何保护您的个人信息</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受
        SSL 加密保护；我们同时对我们网站提供 https
        安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >（三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >（四）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >五、您的权利</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。您有权利查询、更正、管理、删除自己的信息并保护自己的隐私和安全。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(一)访问您的个人信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >除法律法规规定的例外情况，无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。您可以通过如下方式行使您的访问权利</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >帐户信息：如果您希望访问或编辑您的帐户中的个人资料信息、更改您的密码等，您可以在您使用的产品中执行此类操作。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(二)更正或补充您的个人信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >当您需要更新您的个人信息时，或发现我们处理您的个人信息有错误时，您有权作出更正或更新。您可以自行在我们系统内进行更正，或通过反馈与报错等将您的更正申请提交给我们。在您进行更新或更正前，我们可能会先要求您验证自己的身份，然后再处理您的请求。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(三)删除您的个人信息</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1.您可以通过产品中的设置或功能控件删除您的信息。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >账号注销</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >在需要终止使用“莲藕医生（医生端）”账号服务时，符合以下条件的，您可以申请注销您的“莲藕医生（医生端）”账号：</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(1)您仅能申请注销您本人的账号，并依照“莲藕医生（医生端）”的流程进行注销；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(2)您仍应对您在注销账号前且使用“莲藕医生（医生端）”服务期间的行为承担相应责任；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(3)注销成功后，账号记录、功能等将无法恢复或提供。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >如您需要注销您的“莲藕医生（医生端）”账号，请打开莲藕医生（医生端），在【我的】-【系统设置】-【账号安全】-【注销账号】中的账号注销处，按提示进行注销。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2.发生以下情形，您可以向我们提出删除个人信息的请求：</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(1)如果我们处理个人信息的行为违反法律法规；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(2)如果收集、使用您的个人信息，却未征得您的同意；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(3)如果我们处理个人信息的行为违反了与您的约定；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >(4)如果我们终止产品运营及服务。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(四)改变您授权同意的范围</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1.您可以通过解除绑定、修改个人设置、删除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销授权。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(五) 约束信息系统自动决策</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害医药商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >(六) 响应您的上述请求</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15天内做出答复。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >在以下情形中，按照法律法规要求，我们将无法响应您的请求：</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >1、与国家安全、国防安全有关的；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >2、与公共安全、公共卫生、重大公共利益有关的；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >3、与犯罪侦查、起诉、审判和执行判决等有关的；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span>
    </p>
    <p style="font-size: 10pt; line-height: 200%; margin: 2pt 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >6、涉及商业秘密的。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >六、我们如何处理儿童的个人信息</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不得创建自己的用户账户。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >尽管当地法律和习俗对儿童的定义不同，但我们将不满 14
        周岁的任何人均视为儿童。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >七、您的个人信息如何在全球范围转移</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。</span>
    </p>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >八、本隐私政策如何更新</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >我们可能适时会对本隐私政策进行调整或变更，本隐私政策的任何更新将以标注更新时间的方式公布在我们网站上，除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后的7日后生效。如您本隐私政策调整或变更后继续使用我们提供的任一服务或访问我们相关网站的，我们相信这代表您已充分阅读、理解并接受修改后的政策并受其约束。</span>
    </p>
    <h5 style="margin: 0pt">
      <span
        style="
            color: #111111;
            font-family: 微软雅黑;
            font-size: 12pt;
            font-style: normal;
            font-weight: bold;
            vertical-align: baseline;
          "
      >九、如何联系我们</span>
    </h5>
    <p
      style="
          font-size: 10pt;
          line-height: 200%;
          margin: 2pt 0pt;
          text-align: justify;
        "
    >
      <span
        style="
            color: #333333;
            font-family: 微软雅黑;
            font-size: 10pt;
            font-style: normal;
            vertical-align: baseline;
          "
      >如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系，我们将在15天内回复您：dev@lianou.tech</span>
    </p>

  </div>
</template>

<script>
export default {}
</script>

<style scoped>
div p {
  padding: 5px 0;
}
h1,h2,h3,h4,h5{
  padding: 10px 0;
  line-height: 2;
}
</style>
